/** @format */

import {
  Button,
  Checkbox,
  Paper,
  TableContainer,
  Tooltip,
} from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import axios from "axios";
import MaterialTable from "material-table";
import React, { Component } from "react";
import Helper from "../../global/Helper";
export class TableFlightCheckInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rows: 10,
      total: 0,
      flight: [],
      q: "",
    };
    this.columns = [
      {
        title: "วัน และ เวลา",
        field: "t_datetime",
        // type: "datetime",
        align: "center",
        render: (rowData) => {
          return Helper.CastDateTime(rowData.t_datetime);
        },
      },
      {
        title: "เลขที่สัญชาติ / เลขทะเบียน",
        field: "t_callsign",
        align: "center",
      },
      { title: "รหัสเที่ยวบิน", field: "t_flight_no", align: "center" },
      { title: "แบบอากาศยาน", field: "t_air_type", align: "center" },
      { title: "สายการบิน", field: "t_airline", align: "center" },
      {
        title: "ผู้โดยสารในประเทศ",
        field: "td_passenger_dom_f",
        align: "center",
      },
      {
        title: "ผู้โดยสารต่างประเทศ",
        field: "td_passenger_inter_f",
        align: "center",
      },
      {
        title: "เด็กต่ำกว่า 2 ขวบ",
        field: "td_infants_f",
        align: "center",
      },
      { title: "สถานะ", field: "status", align: "center" },
      {
        title: "",
        field: "t_id",
        render: (rowData) => {
          return (
            <Tooltip title="จัดการ" arrow>
              <Button
                className="styleBtn blue w-auto ml-10px"
                href={`/flight-check/details/${rowData.t_id}?goto=${
                  this.props.goto ? this.props.goto : "createinvoice"
                }&${this.state.q}`}
                variant="contained"
                style={{ textTransform: "none", marginRight: "5px" }}
                size="small"
              >
                <AssignmentIcon />
              </Button>
            </Tooltip>
          );
        },
      },
    ];
    if (this.props.isSpecial) {
      this.columns.unshift({
        title: (
          <Checkbox
            color="primary"
            defaultChecked={this.state.allChecked}
            onChange={(e) => {
              let temp = this.state.flight;
              temp.map((v) => {
                v.checked = e.target.checked;
                return v;
              });
              this.setState({
                allChecked: e.target.checked,
                flight: temp,
              });
              this.props.handleFlights(temp);
            }}
          />
        ),
        sorting: false,
        field: "t_id",
        align: "center",
        render: (rowData) => {
          return (
            <Checkbox
              color="primary"
              checked={rowData.checked}
              onChange={(e) => {
                let temp = this.state.flight;
                temp.map((v) => {
                  if (v.t_id === rowData.t_id) {
                    v.checked = e.target.checked;
                  }
                  return v;
                });
                this.setState({
                  allChecked: false,
                  flight: temp,
                });
                this.props.handleFlights(temp);
              }}
            />
          );
        },
      });
    }
  }
  handleFiltering = async (v) => {
    await this.setState({ q: Helper.jsonToQueryString(v) });
    await this.setState({ page: 0 });
    // this.getTransport(0, this.state.rows, Helper.jsonToQueryString(v));
    this.handleChangeRowPerPage(10, v);
  };
  handleChangePage = async (e, page) => {
    await this.setState({ page: page });
    this.getTransport(page, this.state.rows, this.state.q);
  };

  handleChangeRowPerPage = async (row, q) => {
    await this.setState({ rows: row });
    await this.setState({ page: 0 });
    this.getTransport(0, row, Helper.jsonToQueryString(q));
  };

  getTransport = (page = 0, rows = 10, q = "") => {
    this.props.handlerIsLoading(true);
    const startRow = page * rows;
    let airport = "";
    if ([3, 4].includes(Helper.GetUserData().ul_id)) {
      airport = `&filter_airport=${Helper.GetUserData().ap_id}`;
    }
    const queryString = `limit=${rows}&offset=${startRow}${q ? `&${q}` : ""}`;
    const queryIsSpecial = `&is_special=${this.props.isSpecial ? 1 : 0}`;
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/transports/pagination/inv?${queryString}${airport}${queryIsSpecial}`;
    const params = new URLSearchParams(q);
    const m = params.get("type_code");
    axios.get(apiUrl).then(async (res) => {
      await res.data.data.forEach((v) => {
        if (!v.td_arrival_freight_f) {
          if (v.td_status === "3") {
            v.td_arrival_freight_f = v.t_arrival_freight;
          } else {
            v.td_arrival_freight_f = 0;
          }
        }
        v.td_arrival_freight_f = parseFloat(v.td_arrival_freight_f) / 1000;
        v.status = Helper.getStatusFlightCheck(v.td_status);
        v.checked = false;
        if (["PSC", "Mulct-PSC"].includes(m)) {
          v.price = Helper.getCalculatePSC(
            parseInt(v.td_passenger_dom_f),
            parseInt(v.td_passenger_inter_f)
          );
        } else if (["APPS", "Mulct-APPS"].includes(m)) {
          v.price = Helper.getCalculateAPPS(parseInt(v.td_passenger_f));
        } else if (["Landing", "Mulct-Landing"].includes(m)) {
          v.price = Helper.getCalculateLanding(v.td_arrival_freight_f);
        } else if (["Parking", "Mulct-Parking"].includes(m)) {
          v.price = Helper.getCalculateParking(
            v.date_diff,
            v.td_arrival_freight_f
          );
        } else {
          v.price = 0;
        }
        v.discount = 0;
        v.percent = 0;
        v.changePrice = 0;
        v.total = v.price;
        v.remark = "";
      });
      await this.setState({ flight: res.data.data });
      await this.setState({ total: res.data.total });
      this.props.handleFlights(res.data.data);
      this.props.handlerIsLoading(false);
    });
  };

  getParams = (p) => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    return p !== "all" ? url.searchParams.get(p) : window.location.search;
  };
  initData = async (v = "", isSearch = false) => {
    if (this.props.data.length > 0 && !isSearch) {
      this.props.handlerIsLoading(true);
      let temp = this.props.data;
      await temp.forEach((v) => {
        v.status = Helper.getStatusFlightCheck(v.td_status);
        v.checked = false;
      });
      this.setState({ flight: temp, total: temp.length });
      this.props.handlerIsLoading(false);
    } else {
      this.handleFiltering(v);
    }
  };
  componentDidMount() {}
  render() {
    return (
      <Paper className="tabelData">
        <TableContainer>
          <MaterialTable
            title=""
            columns={this.columns}
            data={this.state.flight}
            options={{
              pageSize: 10,
              pageSizeOptions: [10, 25, 50, 100],
              search: true,
              exportButton: false,
              filtering: false,
              actionsColumnIndex: -1,
              grouping: true,
              sorting: true,
              headerStyle: {
                position: "sticky",
                top: "0",
              },
            }}
          />
        </TableContainer>
      </Paper>
    );
  }
}

export default TableFlightCheckInvoice;

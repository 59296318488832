/** @format */

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import axios from "axios";
import fileDownload from "js-file-download";
import React, { useEffect, useState } from "react";
import Helper from "../global/Helper";
import Notifications from "../global/Notifications";

export default function StickyHeadTable(props) {
  const [items, setItems] = useState([]);
  useEffect(() => {
    if (Object.keys(props.filter).length === 0) return;
    if (!props.filter.filter_airport) return;
    props.handlerIsLoading(true);
    const query = Helper.jsonToQueryString(props.filter);
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/report-download-pdf-list?${query}`;
    console.log(apiUrl);
    axios.get(apiUrl).then((res) => {
      console.log(res.data);
      if (!res.data.data) {
        Notifications.NotiError({ msg: "ไม่พบข้อมูล" });
      }
      setItems(res.data.data);
      props.handlerIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filter]);

  const getButton = (row, type, action) => {
    const isHalf = type === "PSC_HALF";
    const revenue = isHalf ? "PSC" : type;
    if (!row.i_revenue.includes(revenue)) return "-";
    const key = `is_${type.toLowerCase()}_${action.toLowerCase()}`;
    if (row[key] === 1) {
      return (
        <Button
          className="styleBtn lightblue"
          onClick={() => {
            download(row, type, action);
          }}
        >
          ดาวน์โหลด
        </Button>
      );
    } else {
      return "รออัพโหลด";
    }
  };

  const download = (row, type, action) => {
    const query = Helper.jsonToQueryString({
      al_id: row.al_id,
      type: type,
      action: action,
      ...props.filter,
    });
    Notifications.NotiAsync(
      axios.get(
        `${process.env.REACT_APP_API_URL}/v1/report-download-pdf?${query}`,
        {
          responseType: "blob",
        }
      ),
      (resp) => {
        props.handlerIsLoading(false);
        fileDownload(resp.data, "download.zip");
        Notifications.NotiSuccess();
      },
      (err) => {
        console.log(err.response.data);
        props.handlerIsLoading(false);
        Notifications.NotiError({ msg: err.response.data.msg });
      }
    );
  };

  return (
    <Paper className="tabelData">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell rowSpan={3} align="center" key={"title1"}>
                สายการบิน
              </TableCell>
              <TableCell colSpan={4} align="center" key={"title2"}>
                PSC
              </TableCell>
              <TableCell rowSpan={2} colSpan={2} align="center" key={"title3"}>
                Landing
              </TableCell>
              <TableCell rowSpan={2} colSpan={2} align="center" key={"title4"}>
                Parking
              </TableCell>
              <TableCell rowSpan={2} colSpan={2} align="center" key={"title5"}>
                APPS
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} align="center" key={"title6"}>
                1 - 15
              </TableCell>
              <TableCell colSpan={2} align="center" key={"title7"}>
                16 - สิ้นเดือน
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" key={"title8"}>
                Bill
              </TableCell>
              <TableCell align="center" key={"title9"}>
                Receipt
              </TableCell>
              <TableCell align="center" key={"title10"}>
                Bill
              </TableCell>
              <TableCell align="center" key={"title11"}>
                Receipt
              </TableCell>
              <TableCell align="center" key={"title12"}>
                Bill
              </TableCell>
              <TableCell align="center" key={"title13"}>
                Receipt
              </TableCell>
              <TableCell align="center" key={"title14"}>
                Bill
              </TableCell>
              <TableCell align="center" key={"title15"}>
                Receipt
              </TableCell>
              <TableCell align="center" key={"title16"}>
                Bill
              </TableCell>
              <TableCell align="center" key={"title17"}>
                Receipt
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items &&
              items.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.al_name}</TableCell>
                  <TableCell align="center">
                    {getButton(row, "PSC", "BILL")}
                  </TableCell>
                  <TableCell align="center">
                    {getButton(row, "PSC", "RECEIPT")}
                  </TableCell>
                  <TableCell align="center">
                    {getButton(row, "PSC_HALF", "BILL")}
                  </TableCell>
                  <TableCell align="center">
                    {getButton(row, "PSC_HALF", "RECEIPT")}
                  </TableCell>
                  <TableCell align="center">
                    {getButton(row, "Landing", "BILL")}
                  </TableCell>
                  <TableCell align="center">
                    {getButton(row, "Landing", "RECEIPT")}
                  </TableCell>
                  <TableCell align="center">
                    {getButton(row, "Parking", "BILL")}
                  </TableCell>
                  <TableCell align="center">
                    {getButton(row, "Parking", "RECEIPT")}
                  </TableCell>
                  <TableCell align="center">
                    {getButton(row, "APPS", "BILL")}
                  </TableCell>
                  <TableCell align="center">
                    {getButton(row, "APPS", "RECEIPT")}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

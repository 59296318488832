/** @format */

import {
  Button,
  Checkbox,
  Paper,
  TableContainer,
  TablePagination,
  Tooltip,
} from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import axios from "axios";
import MaterialTable from "material-table";
import React, { Component } from "react";
import Helper from "../global/Helper";
import Notifications from "../global/Notifications";
export class TableFlightCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rows: 10,
      total: 0,
      flight: [],
      q: "",
    };
    this.user = JSON.parse(localStorage.getItem("DataUser"));
    this.columns = [];
    this.columns = [
      ...this.columns,
      {
        title: "วัน และ เวลา",
        field: "t_datetime",
        // type: "datetime",
        align: "center",
        render: (rowData) => {
          return Helper.CastDateTime(rowData.t_datetime);
        },
      },
      { title: "สายการบิน", field: "al_name", align: "center" },
      {
        title: "เลขที่สัญชาติ / เลขทะเบียน",
        field: "t_callsign",
        align: "center",
      },
      { title: "รหัสเที่ยวบิน", field: "t_flight_no", align: "center" },
      {
        title: "น้ำหนัก",
        field: "t_arrival_freight",
        align: "center",
        render: (rowData) => {
          return Helper.CastCurrency(rowData.t_arrival_freight);
        },
      },
      {
        title: "PSC",
        field: "td_psc",
        align: "center",
        render: (rowData) => {
          return (
            <Checkbox
              color="primary"
              checked={rowData.td_psc_checked}
              disabled={rowData.td_psc === "1"}
              onChange={(e) => {
                let temp = this.state.flight;
                temp.map((v) => {
                  if (v.t_id === rowData.t_id) {
                    v.td_psc_checked = e.target.checked;
                    v.is_update = 1;
                    if (!e.target.checked) v.td_psc = "0";
                  }
                  return v;
                });
                this.setState({
                  flight: temp,
                });
              }}
            />
          );
        },
      },
      {
        title: "APPS",
        field: "td_apps",
        align: "center",
        render: (rowData) => {
          return (
            <Checkbox
              color="primary"
              checked={rowData.td_apps_checked}
              disabled={rowData.td_apps === "1"}
              onChange={(e) => {
                let temp = this.state.flight;
                temp.map((v) => {
                  if (v.t_id === rowData.t_id) {
                    v.td_apps_checked = e.target.checked;
                    v.is_update = 1;
                    if (!e.target.checked) v.td_apps = "0";
                  }
                  return v;
                });
                this.setState({
                  flight: temp,
                });
              }}
            />
          );
        },
      },
      {
        title: "Landing",
        field: "td_landing",
        align: "center",
        render: (rowData) => {
          return (
            <Checkbox
              color="primary"
              checked={rowData.td_landing_checked}
              disabled={rowData.td_landing === "1"}
              onChange={(e) => {
                let temp = this.state.flight;
                temp.map((v) => {
                  if (v.t_id === rowData.t_id) {
                    v.td_landing_checked = e.target.checked;
                    v.is_update = 1;
                    if (!e.target.checked) v.td_landing = "0";
                  }
                  return v;
                });
                this.setState({
                  flight: temp,
                });
              }}
            />
          );
        },
      },
      {
        title: "Parking",
        field: "td_parking",
        align: "center",
        render: (rowData) => {
          return (
            <Checkbox
              color="primary"
              checked={rowData.td_parking_checked}
              disabled={rowData.td_parking === "1"}
              onChange={(e) => {
                let temp = this.state.flight;
                temp.map((v) => {
                  if (v.t_id === rowData.t_id) {
                    v.td_parking_checked = e.target.checked;
                    v.is_update = 1;
                    if (!e.target.checked) v.td_parking = "0";
                  }
                  return v;
                });
                this.setState({
                  flight: temp,
                });
              }}
            />
          );
        },
      },
      {
        title: "",
        field: "t_id",
        sorting: false,
        render: (rowData) => {
          return (
            <div>
              <Tooltip title="จัดการ" arrow>
                <Button
                  className="styleBtn blue w-auto ml-10px"
                  href={`/flight-check/details/${rowData.t_id}`}
                  variant="contained"
                  size="small"
                >
                  <AssignmentIcon />
                </Button>
              </Tooltip>
            </div>
          );
        },
      },
    ];
  }
  handleFiltering = async (v) => {
    await this.setState({ q: Helper.jsonToQueryString(v) });
    await this.setState({ page: 0 });
    v.filter_status = -2;
    this.getTransport(0, this.state.rows, Helper.jsonToQueryString(v));
  };
  handleChangePage = async (e, page) => {
    await this.setState({ page: page });
    this.getTransport(page, this.state.rows, this.state.q);
  };

  handleChangeRowPerPage = async (row) => {
    await this.setState({ rows: row });
    await this.setState({ page: 0 });
    this.getTransport(0, row, this.state.q);
  };

  getTransport = (page = 0, rows = 10, q = "") => {
    this.props.handlerIsLoading(true);
    const startRow = page * rows;
    const queryString = `limit=${rows}&offset=${startRow}${q ? `&${q}` : ""}`;
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/transports/pagination?${queryString}`;
    axios.get(apiUrl).then(async (res) => {
      await res.data.data.map((v) => {
        if (v.td_psc === "2") {
          v.td_psc_checked = true;
        }
        if (v.td_apps === "2") {
          v.td_apps_checked = true;
        }
        if (v.td_landing === "2") {
          v.td_landing_checked = true;
        }
        if (v.td_parking === "2") {
          v.td_parking_checked = true;
        }
        return v;
      });
      await this.setState({ flight: res.data.data });
      await this.setState({ total: res.data.total });
      this.props.handlerIsLoading(false);
    });
  };
  getDataDisMount = () => {
    const user = JSON.parse(localStorage.getItem("DataUser"));
    if ([1, 2, 3].includes(user.ul_id)) {
      this.getTransport(0, this.state.rows, this.state.q);
    }
  };
  confirmChecked = async () => {
    this.props.handlerIsLoading(true);
    await this.state.flight.forEach(async (i) => {
      if (i.is_update === 1) {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/v1/transports/except/${i.t_id}`,
          {
            td_psc_checked: i.td_psc_checked,
            td_apps_checked: i.td_apps_checked,
            td_landing_checked: i.td_landing_checked,
            td_parking_checked: i.td_parking_checked,
          }
        );
      }
    });
    this.props.handlerIsLoading(false);
    Notifications.NotiSuccess();
  };
  componentDidMount() {}
  render() {
    return (
      <Paper className="tabelData">
        <TableContainer>
          <MaterialTable
            title={
              <Button
                className={`styleBtn blue ${
                  this.user.ul_id === 4 ? "d-none" : ""
                }`}
                variant="contained"
                style={{ textTransform: "none", width: "auto" }}
                onClick={() => {
                  this.confirmChecked();
                }}
                size="small"
              >
                ยืนยันที่เลือก
              </Button>
            }
            columns={this.columns}
            data={this.state.flight}
            options={{
              pageSize: 10,
              pageSizeOptions: [10, 25, 50, 100],
              search: true,
              exportButton: false,
              filtering: false,
              actionsColumnIndex: -1,
              grouping: true,
            }}
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  rowsPerPage={this.state.rows}
                  count={this.state.total ? parseInt(this.state.total) : 0}
                  page={this.state.page}
                  onChangePage={(e, page) => this.handleChangePage(e, page)}
                  onChangeRowsPerPage={(event) => {
                    props.onChangeRowsPerPage(event);
                    this.handleChangeRowPerPage(event.target.value);
                  }}
                />
              ),
            }}
          />
        </TableContainer>
      </Paper>
    );
  }
}

export default TableFlightCheck;

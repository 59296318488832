/** @format */

import {
  Button,
  Paper,
  TableContainer,
  TablePagination,
  Tooltip,
} from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import axios from "axios";
import MaterialTable from "material-table";
import React, { Component } from "react";
import Helper from "../global/Helper";
export class TableIncompleteInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rows: 10,
      total: 0,
      flight: [],
      q: "",
      allChecked: false,
    };
    this.user = JSON.parse(localStorage.getItem("DataUser"));
    this.columns = [];
    this.columns = [
      ...this.columns,
      {
        title: "วัน และ เวลา",
        field: "t_datetime",
        // type: "datetime",
        align: "center",
        render: (rowData) => {
          return Helper.CastDateTime(rowData.t_datetime);
        },
      },
      { title: "สายการบิน", field: "t_airline", align: "center" },
      {
        title: "เลขที่สัญชาติ / เลขทะเบียน",
        field: "t_callsign",
        align: "center",
      },
      { title: "รหัสเที่ยวบิน", field: "t_flight_no", align: "center" },
      {
        title: "น้ำหนัก",
        field: "t_arrival_freight",
        align: "center",
        render: (rowData) => {
          return Helper.CastCurrency(rowData.t_arrival_freight);
        },
      },
      {
        title: "",
        field: "t_id",
        sorting: false,
        export: false,
        render: (rowData) => {
          return (
            <div>
              <Tooltip title="จัดการ" arrow>
                <Button
                  className="styleBtn blue w-auto ml-10px"
                  href={`/flight-check/details/${rowData.t_id}`}
                  variant="contained"
                  size="small"
                >
                  <AssignmentIcon />
                </Button>
              </Tooltip>
            </div>
          );
        },
      },
    ];
  }
  handleFiltering = async (v) => {
    await this.setState({ q: Helper.jsonToQueryString(v) });
    await this.setState({ page: 0 });
    this.getTransport(0, this.state.rows, Helper.jsonToQueryString(v));
  };
  handleChangePage = async (e, page) => {
    await this.setState({ page: page });
    this.getTransport(page, this.state.rows, this.state.q);
  };

  handleChangeRowPerPage = async (row) => {
    await this.setState({ rows: row });
    await this.setState({ page: 0 });
    this.getTransport(0, row, this.state.q);
  };

  getTransport = (page = 0, rows = 10, q = "") => {
    this.props.handlerIsLoading(true);
    const startRow = page * rows;
    const queryString = `limit=${rows}&offset=${startRow}${
      q ? `&${q}` : ""
    }&is_incomplete=1`;
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/transports/pagination?${queryString}`;
    axios.get(apiUrl).then(async (res) => {
      await res.data.data.map((v) => {
        v.checked = false;
        return v;
      });
      await this.setState({ flight: res.data.data });
      await this.setState({ total: res.data.total });
      this.props.handlerIsLoading(false);
    });
  };
  getDataDisMount = () => {
    const user = JSON.parse(localStorage.getItem("DataUser"));
    if ([1, 2, 3].includes(user.ul_id)) {
      this.getTransport(0, this.state.rows, this.state.q);
    }
  };
  componentDidMount() {
    // this.getDataDisMount();
  }
  render() {
    return (
      <Paper className="tabelData">
        <TableContainer>
          <MaterialTable
            title=""
            columns={this.columns}
            data={this.state.flight}
            options={{
              pageSize: 10,
              pageSizeOptions: [10, 25, 50, 100],
              search: true,
              exportButton: true,
              filtering: false,
              actionsColumnIndex: -1,
              grouping: true,
            }}
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  rowsPerPage={this.state.rows}
                  count={this.state.total ? parseInt(this.state.total) : 0}
                  page={this.state.page}
                  onChangePage={(e, page) => this.handleChangePage(e, page)}
                  onChangeRowsPerPage={(event) => {
                    props.onChangeRowsPerPage(event);
                    this.handleChangeRowPerPage(event.target.value);
                  }}
                />
              ),
            }}
          />
        </TableContainer>
      </Paper>
    );
  }
}

export default TableIncompleteInformation;

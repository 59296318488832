/** @format */

import React, { Component } from "react";

// Components Page
import { Button } from "@material-ui/core";
import axios from "axios";
import fileDownload from "js-file-download";
import TableDownloadPDF from "../datatable/TableDownloadPDF";
import FillterData7 from "../global/FillterData7";
import Helper from "../global/Helper";
import Notifications from "../global/Notifications";

export class ReportDownloadPDF extends Component {
  constructor(props) {
    super(props);
    this.handlerIsLoading = this.handlerIsLoading.bind(this);
    this.handleFiltering = this.handleFiltering.bind(this);
    this.state = {
      filter: {},
    };
  }
  handlerIsLoading = (v) => {
    this.props.handlerIsLoading(v);
  };
  handleFiltering = (v) => {
    this.setState({ filter: v });
  };
  download = (action) => {
    if (Object.keys(this.state.filter).length === 0) {
      Notifications.NotiError({ msg: "กรุณาค้นหาข้อมูลก่อน" });
      return;
    }
    if (!this.state.filter.filter_airport) {
      Notifications.NotiError({ msg: "กรุณาเลือกสนามบิน" });
      return;
    }
    if (!this.state.filter.filter_month) {
      Notifications.NotiError({ msg: "กรุณาเลือกเดือน" });
      return;
    }
    if (!this.state.filter.filter_year) {
      Notifications.NotiError({ msg: "กรุณาเลือกปี" });
      return;
    }
    const query = Helper.jsonToQueryString({
      action: action,
      ...this.state.filter,
    });
    // this.handlerIsLoading(true);
    Notifications.NotiAsync(
      axios.get(
        `${process.env.REACT_APP_API_URL}/v1/report-download-pdf?${query}`,
        {
          responseType: "blob",
        }
      ),
      (resp) => {
        this.handlerIsLoading(false);
        fileDownload(resp.data, "download.zip");
        Notifications.NotiSuccess();
      },
      (err) => {
        console.log(err.response.data);
        this.handlerIsLoading(false);
        Notifications.NotiError({ msg: err.response.data.msg });
      }
    );
  };

  render() {
    return (
      <div>
        <div className="box-S4 mgBottom">
          <div className="bPageName flex-between">
            <div>Download PDF</div>
          </div>
        </div>
        <FillterData7
          handleFiltering={this.handleFiltering.bind(this)}
          fillteringPage="report-download-pdf"
        />
        <div className="box-S4 flex-between mgBottom">
          <div className="bContentMain">
            <div className="bNameContent">Download PDF</div>
            <div className="bContent">
              <div style={{ marginBottom: "10px" }}>
                <Button
                  className="styleBtn blue"
                  style={{ width: "auto", marginRight: "10px" }}
                  onClick={() => {
                    this.download("BILL");
                  }}
                >
                  Download Bill Payment
                </Button>
                <Button
                  className="styleBtn blue"
                  style={{ width: "auto", marginRight: "10px" }}
                  onClick={() => {
                    this.download("RECEIPT");
                  }}
                >
                  Download Receipt
                </Button>
              </div>
              <TableDownloadPDF
                handlerIsLoading={this.handlerIsLoading.bind(this)}
                filter={this.state.filter}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReportDownloadPDF;

/** @format */

import {
  Button,
  Checkbox,
  Paper,
  TableContainer,
  TablePagination,
  Tooltip,
} from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import axios from "axios";
import fileDownload from "js-file-download";
import MaterialTable from "material-table";
import React, { Component } from "react";
import Helper from "../../global/Helper";
import Notifications from "../../global/Notifications";
export class TableFlightCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rows: 10,
      total: 0,
      flight: [],
      q: "",
      allChecked: false,
    };
    this.user = JSON.parse(localStorage.getItem("DataUser"));
    this.columns = [];
    if (this.user.ul_id !== 4) {
      this.columns = [
        {
          title: (
            <Checkbox
              color="primary"
              defaultChecked={this.state.allChecked}
              onChange={(e) => {
                let temp = this.state.flight;
                temp.map((v) => {
                  v.checked = e.target.checked;
                  return v;
                });
                this.setState({
                  allChecked: e.target.checked,
                  flight: temp,
                });
              }}
            />
          ),
          sorting: false,
          field: "t_id",
          align: "center",
          render: (rowData) => {
            return (
              <Checkbox
                color="primary"
                checked={rowData.checked}
                onChange={(e) => {
                  let temp = this.state.flight;
                  temp.map((v) => {
                    if (v.t_id === rowData.t_id) {
                      v.checked = e.target.checked;
                    }
                    return v;
                  });
                  this.setState({
                    allChecked: false,
                    flight: temp,
                  });
                }}
              />
            );
          },
        },
      ];
    }
    this.columns = [
      ...this.columns,
      {
        title: "วัน และ เวลา",
        field: "t_datetime",
        // type: "datetime",
        align: "center",
        render: (rowData) => {
          return Helper.CastDateTime(rowData.t_datetime);
        },
      },
      { title: "สายการบิน", field: "t_airline", align: "center" },
      {
        title: "เลขที่สัญชาติ / เลขทะเบียน",
        field: "t_callsign",
        align: "center",
      },
      { title: "รหัสเที่ยวบิน", field: "t_flight_no", align: "center" },
      {
        title: "น้ำหนัก",
        field: "t_arrival_freight",
        align: "center",
        render: (rowData) => {
          return Helper.CastCurrency(rowData.t_arrival_freight);
        },
      },
      {
        title: "ผู้โดยสารในประเทศ",
        field: "t_passenger_dom",
        align: "center",
      },
      {
        title: "ผู้โดยสารต่างประเทศ",
        field: "t_passenger_inter",
        align: "center",
      },
      {
        title: "เด็กต่ำกว่า 2 ขวบ",
        field: "t_infants",
        align: "center",
      },
      {
        title: "สถานะ",
        field: "td_status",
        align: "center",
        render: (rowData) => {
          return this.getStatus(rowData.td_status);
        },
      },
      {
        title: "",
        field: "t_id",
        sorting: false,
        render: (rowData) => {
          return (
            <div>
              <Tooltip title="จัดการ" arrow>
                <Button
                  className="styleBtn blue w-auto ml-10px"
                  href={`/flight-check/details/${rowData.t_id}`}
                  variant="contained"
                  size="small"
                >
                  <AssignmentIcon />
                </Button>
              </Tooltip>
              {rowData.td_upload && (
                <Tooltip title="ดาวน์โหลด" arrow>
                  <Button
                    className="styleBtn green w-auto ml-10px"
                    onClick={() => {
                      this.getFile(rowData.td_upload);
                    }}
                    variant="contained"
                    size="small"
                  >
                    <SystemUpdateAltIcon />
                  </Button>
                </Tooltip>
              )}
            </div>
          );
        },
      },
    ];
  }
  handleFiltering = async (v) => {
    await this.setState({ q: Helper.jsonToQueryString(v) });
    await this.setState({ page: 0 });
    this.getTransport(0, this.state.rows, Helper.jsonToQueryString(v));
  };
  handleChangePage = async (e, page) => {
    await this.setState({ page: page });
    this.getTransport(page, this.state.rows, this.state.q);
  };

  handleChangeRowPerPage = async (row) => {
    await this.setState({ rows: row });
    await this.setState({ page: 0 });
    this.getTransport(0, row, this.state.q);
  };

  getStatus = (i) => {
    let cls = "";
    let text = "-";
    switch (i) {
      case "1":
        cls = "cancel";
        text = "ไม่ตรงสถิติ";
        break;
      case "2":
        cls = "complete";
        text = "ตรงสถิติ";
        break;
      case "3":
        cls = "complete";
        text = "ยืนยันแล้ว";
        break;
      default:
        cls = "pending";
        text = "ไม่ยืนยัน";
        break;
    }
    return (
      <div className={`TagStatus ${cls}`} style={{ margin: "auto" }}>
        {text}
      </div>
    );
  };
  getTransport = (page = 0, rows = 10, q = "") => {
    this.props.handlerIsLoading(true);
    const startRow = page * rows;
    const queryString = `limit=${rows}&offset=${startRow}${q ? `&${q}` : ""}`;
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/transports/pagination?${queryString}`;
    axios.get(apiUrl).then(async (res) => {
      await res.data.data.map((v) => {
        v.checked = false;
        return v;
      });
      await this.setState({ flight: res.data.data });
      await this.setState({ total: res.data.total });
      this.props.handlerIsLoading(false);
    });
  };
  getFile = (td_upload) => {
    this.props.handlerIsLoading(true);
    Notifications.NotiAsync(
      axios.get(
        `${process.env.REACT_APP_API_URL}/v1/download/flights/${td_upload}`,
        {
          responseType: "blob",
        }
      ),
      (resp) => {
        fileDownload(resp.data, td_upload);
        this.props.handlerIsLoading(false);
        Notifications.NotiSuccess();
      },
      (err) => {
        this.props.handlerIsLoading(false);
        Notifications.NotiError({ msg: err.response.data.msg });
      }
    );
  };
  confirmCheckedRows = async () => {
    const DataUser = JSON.parse(localStorage.getItem("DataUser"));
    const setA = DataUser.ul_id === 4 || DataUser.ul_id === 1;
    const setF = DataUser.ul_id === 3 || DataUser.ul_id === 1;
    this.props.handlerIsLoading(true);
    let isCheckCallsignOrFlightNo = false;
    await this.state.flight.forEach(async (formFlight) => {
      if (formFlight.checked) {
        if (!formFlight.t_callsign || !formFlight.t_flight_no) {
          isCheckCallsignOrFlightNo = true;
          return;
        }
        let formFlightDetail = {};
        if (setA) {
          formFlightDetail = {
            ...formFlightDetail,
            td_arrival_freight_a: formFlight.t_arrival_freight,
            td_infants_a: formFlight.t_infants,
            td_passenger_a: formFlight.t_passenger,
            td_passenger_dom_a: formFlight.t_passenger_dom,
            td_passenger_inter_a: formFlight.t_passenger_inter,
            td_passenger_transfer_a: formFlight.t_passenger_transfer,
            td_passenger_transit_a: formFlight.t_passenger_transit,
            td_datetime_a: formFlight.t_datetime,
            td_air_type_a: formFlight.t_air_type,
          };
        }
        if (setF) {
          formFlightDetail = {
            ...formFlightDetail,
            td_arrival_freight_f: formFlight.t_arrival_freight,
            td_infants_f: formFlight.t_infants,
            td_passenger_f: formFlight.t_passenger,
            td_passenger_dom_f: formFlight.t_passenger_dom,
            td_passenger_inter_f: formFlight.t_passenger_inter,
            td_passenger_transfer_f: formFlight.t_passenger_transfer,
            td_passenger_transit_f: formFlight.t_passenger_transit,
            td_datetime_f: formFlight.t_datetime,
            td_air_type_f: formFlight.t_air_type,
          };
        }
        formFlightDetail = {
          ...formFlightDetail,
          td_used: 0,
          id: formFlight.t_id,
          td_status: 3,
          user_id: DataUser.u_id,
        };
        let t = "";
        if (DataUser.ul_id === 1) t = "all";
        if (DataUser.ul_id === 4) t = "A";
        if (DataUser.ul_id === 3) t = "F";
        await axios.put(
          `${process.env.REACT_APP_API_URL}/v1/transports/td/${formFlight.t_id}`,
          {
            formFlightDetail,
            formFlight,
            type: t,
          }
        );
      }
    });
    this.props.handlerIsLoading(false);
    if (isCheckCallsignOrFlightNo) {
      Notifications.NotiWarning({
        msg: "กรุณาตรวจสอบข้อมูลหมายเลขอากาศยาน น่ำหนักอากาศยาน และหมายเลขเที่ยวบิน",
      });
      return;
    }

    this.getDataDisMount();
    this.setState({
      allChecked: false,
    });
  };
  getDataDisMount = () => {
    const user = JSON.parse(localStorage.getItem("DataUser"));
    if ([1, 2, 3].includes(user.ul_id)) {
      this.getTransport(0, this.state.rows, this.state.q);
    }
  };
  componentDidMount() {
    // this.getDataDisMount();
  }
  render() {
    return (
      <Paper className="tabelData">
        <TableContainer>
          <MaterialTable
            title={
              <Button
                className={`styleBtn blue ${
                  this.user.ul_id === 4 ? "d-none" : ""
                }`}
                variant="contained"
                style={{ textTransform: "none", width: "auto" }}
                onClick={() => {
                  this.confirmCheckedRows();
                }}
                size="small"
              >
                ยืนยันที่เลือก
              </Button>
            }
            columns={this.columns}
            data={this.state.flight}
            options={{
              pageSize: 10,
              pageSizeOptions: [10, 25, 50, 100],
              search: true,
              exportButton: false,
              filtering: false,
              actionsColumnIndex: -1,
              grouping: true,
            }}
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  rowsPerPage={this.state.rows}
                  count={this.state.total ? parseInt(this.state.total) : 0}
                  page={this.state.page}
                  onChangePage={(e, page) => this.handleChangePage(e, page)}
                  onChangeRowsPerPage={(event) => {
                    props.onChangeRowsPerPage(event);
                    this.handleChangeRowPerPage(event.target.value);
                  }}
                />
              ),
            }}
          />
        </TableContainer>
      </Paper>
    );
  }
}

export default TableFlightCheck;

/** @format */

import { Paper, TableContainer, TextField } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { Component } from "react";
import Helper from "../../global/Helper";
export class TableFlightCheckInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rows: 10,
      total: 0,
      flight: [],
      q: "",
      globalPercent: 0,
      mode: "",
      getTotalPrice: 0,
      getDiscount: 0,
      getTotalPriceChange: 0,
      getTotal: 0,
    };
    this.handlerIsLoading = this.handlerIsLoading.bind(this);
    this.handlerTimeout = null;
  }

  handlerIsLoading = (v) => {
    this.props.handlerIsLoading(v);
  };
  getStateValue = (v) => {
    return this.state[v];
  };
  getSummary = () => {
    let sumTotalPrice = 0,
      sumDiscount = 0,
      sumPriceChange = 0,
      sumTotal = 0,
      sumDom = 0,
      sumInter = 0,
      sumTotalDom = 0,
      sumTotalInter = 0,
      sumDiscountDom = 0,
      sumDiscountInter = 0,
      sumPriceChangeDom = 0,
      sumPriceChangeInter = 0,
      sumDiscountFromPercent = 0;
    this.state.flight.forEach((i) => {
      sumTotalPrice = sumTotalPrice + parseFloat(i.price);
      sumTotal = sumTotal + parseFloat(i.total);
      if (i.tt_dom === "0") {
        sumDom = sumDom + parseFloat(i.price);
        sumTotalDom = sumTotalDom + parseFloat(i.total);
        sumDiscountDom = sumDiscountDom + parseFloat(i.discount);
        sumPriceChangeDom = sumPriceChangeDom + parseFloat(i.changePrice);
      } else {
        sumInter = sumInter + parseFloat(i.price);
        sumTotalInter = sumTotalInter + parseFloat(i.total);
        sumDiscountInter = sumDiscountInter + parseFloat(i.discount);
        sumPriceChangeInter = sumPriceChangeInter + parseFloat(i.changePrice);
      }
      sumDiscountFromPercent = sumDiscountFromPercent + parseFloat(i.discount);
    });
    // console.log("sumTotalPrice", sumTotalPrice);
    // console.log("sumDiscountDom", sumDiscountDom);
    // console.log("sumDiscountInter", sumDiscountInter);
    // console.log("sumPriceChangeDom", sumPriceChangeDom);
    // console.log("sumPriceChangeInter", sumPriceChangeInter);
    // console.log("sumTotal", sumTotal);
    // console.log("sumDiscount", sumDiscount);
    // console.log("sumPriceChange", sumPriceChange);

    this.setState({
      getTotalPrice: Helper.CastCurrency(sumTotalPrice),
      getDiscount: Helper.CastCurrency(sumDiscountDom + sumDiscountInter),
      getTotalPriceChange: Helper.CastCurrency(
        sumPriceChangeDom + sumPriceChangeInter
      ),
      getTotal: Helper.CastCurrency(sumTotal),
      calculateDiscount: Helper.CastCurrency(
        sumTotalPrice - (sumDiscount + sumPriceChange)
      ),
    });
    this.props.handleSummaryResult(
      sumTotalPrice,
      parseFloat(sumDiscountDom) + parseFloat(sumPriceChangeDom),
      sumPriceChangeDom + sumPriceChangeInter,
      sumDiscountFromPercent,
      sumTotalPrice +
        sumDiscountDom +
        sumPriceChangeDom +
        sumDiscountInter +
        sumPriceChangeInter,
      this.state.flight,
      sumDom,
      sumInter,
      sumTotalDom,
      sumTotalInter,
      parseFloat(sumDiscountInter) + parseFloat(sumPriceChangeInter)
    );
  };

  initData = async (v = "", isSearch = false) => {
    if (this.props.data.length > 0 && !isSearch) {
      this.props.handlerIsLoading(true);
      let temp = this.props.data;
      if (!this.props.isCreate) {
        await temp.forEach((v) => {
          v.price = v.id_price ? v.id_price : v.price;
          v.discount = v.id_dis_bath ? v.id_dis_bath : v.discount;
          v.percent = v.id_dis_per ? v.id_dis_per : v.percent;
          v.changePrice = v.id_adjust ? v.id_adjust : v.changePrice;
          v.total = v.id_sum ? v.id_sum : v.total;
          v.remark = v.id_remark ? v.id_remark : v.remark;
          v.discount_from_percent = 0;
        });
      }
      this.setState({ flight: temp, total: temp.length }, () => {
        this.getSummary();
      });
      this.props.handlerIsLoading(false);
    }
  };
  componentDidMount() {}
  render() {
    return (
      <div>
        <Paper className="tabelData">
          <TableContainer>
            <MaterialTable
              title=""
              columns={[
                {
                  title: "วัน และ เวลา",
                  field: "t_datetime",
                  // type: "datetime",
                  align: "center",
                  render: (rowData) => {
                    return Helper.CastDateTime(rowData.t_datetime);
                  },
                },
                {
                  title: "เลขที่สัญชาติ / เลขทะเบียน",
                  field: "t_callsign",
                  align: "center",
                },
                {
                  title: "รหัสเที่ยวบิน",
                  field: "t_flight_no",
                  align: "center",
                },
                { title: "แบบยานอากาศ", field: "t_air_type", align: "center" },
                {
                  title: "น้ำหนัก",
                  field: "td_arrival_freight_f",
                  align: "center",
                },
                {
                  title: "ผู้โดยสารในประเทศ",
                  field: "td_passenger_dom_f",
                  align: "center",
                },
                {
                  title: "ผู้โดยสารต่างประเทศ",
                  field: "td_passenger_inter_f",
                  align: "center",
                },
                {
                  title: "เด็กต่ำกว่า 2 ขวบ",
                  field: "td_infants_f",
                  align: "center",
                },
                {
                  title: "ยอดเดิม",
                  field: "t_id",
                  align: "center",
                  render: (rowData) => {
                    return <div>{Helper.CastCurrency(rowData.price)}</div>;
                  },
                },
                {
                  title: (
                    <TextField
                      label="ส่วนลด %"
                      type="number"
                      defaultValue={this.state.globalPercent}
                      disabled={this.props.isReadonly}
                      onChange={(e) => {
                        e.target.value =
                          e.target.value >= 0 ? e.target.value : 0;
                        const temp = this.state.flight;
                        temp.forEach((v) => {
                          v.discount =
                            0 - v.price * (parseFloat(e.target.value) / 100);
                          v.percent = parseFloat(e.target.value);
                          v.total = v.price + (v.discount + v.changePrice);
                          v.discount_from_percent = v.discount;
                        });
                        if (this.handlerTimeout)
                          clearTimeout(this.handlerTimeout);
                        this.handlerTimeout = setTimeout(() => {
                          this.setState(
                            {
                              flight: [...temp],
                              globalPercent: e.target.value,
                            },
                            () => {
                              this.getSummary();
                            }
                          );
                        }, 500);
                      }}
                    />
                  ),
                  field: "t_id",
                  align: "center",
                  render: (rowData) => {
                    return (
                      <TextField
                        type="number"
                        disabled={this.props.isReadonly}
                        value={rowData.percent}
                        onChange={(e) => {
                          e.target.value =
                            e.target.value >= 0 ? e.target.value : 0;
                          const temp = this.state.flight;
                          temp.forEach((v) => {
                            if (v.t_id === rowData.t_id) {
                              v.discount = 0 - v.price * (e.target.value / 100);
                              v.percent = parseFloat(e.target.value);
                              v.total = v.price + (v.discount + v.changePrice);
                              v.discount_from_percent = v.discount;
                            }
                          });
                          this.setState(
                            {
                              flight: [...temp],
                            },
                            () => {
                              this.getSummary();
                            }
                          );
                        }}
                      />
                    );
                  },
                },
                {
                  title: "ส่วนลดบาท",
                  field: "t_id",
                  align: "center",
                  render: (rowData) => {
                    return <div>{Helper.CastCurrency(rowData.discount)}</div>;
                  },
                },
                {
                  title: "ปรับยอด",
                  field: "t_id",
                  align: "center",
                  render: (rowData) => {
                    return (
                      <TextField
                        type="number"
                        step={0.1}
                        value={rowData.changePrice}
                        disabled={this.props.isReadonly}
                        onChange={(e) => {
                          const temp = this.state.flight;
                          temp.forEach((v) => {
                            if (v.t_id === rowData.t_id) {
                              v.changePrice = e.target.value;
                              v.total =
                                v.price -
                                v.discount +
                                parseFloat(e.target.value);
                            }
                          });
                          if (this.state.handlerTimeout)
                            clearTimeout(this.state.handlerTimeout);
                          this.setState({
                            handlerTimeout: setTimeout(() => {
                              this.setState(
                                {
                                  flight: [...temp],
                                },
                                () => {
                                  this.getSummary();
                                }
                              );
                            }, 500),
                          });
                        }}
                      />
                    );
                  },
                },
                {
                  title: "คงเหลือ",
                  field: "t_id",
                  align: "center",
                  render: (rowData) => {
                    return <div>{Helper.CastCurrency(rowData.total)}</div>;
                  },
                },
                {
                  title: "หมายเหตุ",
                  field: "t_id",
                  align: "center",
                  render: (rowData) => {
                    return (
                      <TextField
                        label=""
                        disabled={this.props.isReadonly}
                        onChange={(e) => {
                          const temp = this.state.flight;
                          temp.forEach((v) => {
                            if (rowData.t_id === v.t_id) {
                              v.remark = e.target.value;
                            }
                          });
                          this.setState({
                            flight: [...temp],
                          });
                        }}
                      />
                    );
                  },
                },
              ]}
              data={this.state.flight}
              options={{
                pageSize: 10,
                pageSizeOptions: [10, 25, 50, 100],
                search: false,
                exportButton: false,
                filtering: false,
                actionsColumnIndex: -1,
                grouping: true,
                sorting: false,
                headerStyle: {
                  position: "sticky",
                  top: "0",
                },
              }}
            />
          </TableContainer>
        </Paper>
      </div>
    );
  }
}

export default TableFlightCheckInvoice;

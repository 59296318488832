/** @format */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  NativeSelect,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Helper from "./Helper";
import Notifications from "./Notifications";

function FillterData7(props) {
  const startYear = 2019;
  const years = Array.from(
    { length: new Date().getFullYear() - startYear },
    (x, i) => (
      <option key={"y" + i} value={i + 1 + startYear}>
        {i + 1 + startYear}
      </option>
    )
  ).reverse();
  const [form, setState] = useState({
    filter_month: new Date().getMonth() + 1,
    filter_year: new Date().getFullYear(),
    filter_airport: "",
  });
  const [airport, setAirport] = useState([
    <option key="ap0" value="">
      เลือกท่าอากาศยาน
    </option>,
  ]);
  const getAirport = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/v1/airports`).then((res) => {
      res.data.forEach((i) => {
        if (
          (Helper.GetUserData().ap_id &&
            Helper.GetUserData().ap_id.toString()) === i.ap_id.toString() ||
          [1, 2].includes(Helper.GetUserData().ul_id)
        ) {
          setAirport((item) => [
            ...item,
            <option key={"ap" + i.ap_id} value={i.ap_id}>
              {i.ap_name}
            </option>,
          ]);
        }
      });
    });
  };

  const updateField = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const fetch = async () => {
      await getAirport();
      const pagename = "fillter_data_7_" + props.fillteringPage;
      let sesstion = sessionStorage.getItem(pagename);
      if (sesstion) {
        sesstion = JSON.parse(sesstion);
        setState({ ...form, ...sesstion });
        submitFilltering(sesstion);
      }
    };
    fetch();
  }, []);

  const submitFilltering = (data, isButtonClick = false) => {
    const obj = { ...data };
    if (!obj.filter_airport && isButtonClick) {
      Notifications.NotiWarning({ msg: "กรุณาเลือกท่าอากาศยาน" });
    }
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === ""
      ) {
        delete obj[propName];
      }
    }
    sessionStorage.setItem(
      "fillter_data_7_" + props.fillteringPage,
      JSON.stringify(obj)
    );
    props.handleFiltering(obj);
  };
  return (
    <div className="box-S4 flex-between mgBottom">
      <div className="bContentMain">
        <div className="bNameContent">กรองข้อมูล</div>
        <div className="bContent">
          <div className="bRow flex-between-start">
            <div className="box-S1" style={{ marginRight: 10 }}>
              <FormControl fullWidth>
                <InputLabel shrink>ท่าอากาศยาน</InputLabel>
                <NativeSelect
                  native="true"
                  name="filter_airport"
                  value={form.filter_airport}
                  onChange={updateField}
                >
                  {airport}
                </NativeSelect>
                <FormHelperText> </FormHelperText>
              </FormControl>
            </div>
            <div className="box-S1" style={{ marginRight: 10 }}>
              <FormControl fullWidth>
                <InputLabel shrink>ปี</InputLabel>
                <NativeSelect
                  native="true"
                  name="filter_year"
                  value={form.filter_year}
                  onChange={updateField}
                >
                  {years}
                </NativeSelect>
                <FormHelperText> </FormHelperText>
              </FormControl>
            </div>
            <div className="box-S1">
              <FormControl fullWidth>
                <InputLabel shrink>เดือน</InputLabel>
                <NativeSelect
                  native="true"
                  name="filter_month"
                  value={form.filter_month}
                  onChange={updateField}
                >
                  <option key="m1" value="1">
                    มกราคม
                  </option>
                  <option key="m2" value="2">
                    กุมภาพันธ์
                  </option>
                  <option key="m3" value="3">
                    มีนาคม
                  </option>
                  <option key="m4" value="4">
                    เมษายน
                  </option>
                  <option key="m5" value="5">
                    พฤษภาคม
                  </option>
                  <option key="m6" value="6">
                    มิถุนายน
                  </option>
                  <option key="m7" value="7">
                    กรกฎาคม
                  </option>
                  <option key="m8" value="8">
                    สิงหาคม
                  </option>
                  <option key="m9" value="9">
                    กันยายน
                  </option>
                  <option key="m10" value="10">
                    ตุลาคม
                  </option>
                  <option key="m11" value="11">
                    พฤศจิกายน
                  </option>
                  <option key="m12" value="12">
                    ธันวาคม
                  </option>
                </NativeSelect>
                <FormHelperText> </FormHelperText>
              </FormControl>
            </div>
            <div className="box-S1" style={{ marginLeft: 10 }}>
              <Button
                className="styleBtn blue"
                onClick={() => {
                  submitFilltering(form, true);
                }}
              >
                ค้นหา
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FillterData7;

/** @format */

import {
  Button,
  Checkbox,
  Paper,
  TableContainer,
  TablePagination,
} from "@material-ui/core";
import axios from "axios";
import MaterialTable from "material-table";
import React, { Component } from "react";
import Helper from "../global/Helper";
import Notifications from "../global/Notifications";
export class TableImportTransport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rows: 10,
      total: 0,
      flight: [],
      q: "",
      allChecked: false,
    };
    this.user = JSON.parse(localStorage.getItem("DataUser"));
    this.columns = [];
    if (this.user.ul_id !== 4) {
      this.columns = [
        {
          title: (
            <Checkbox
              color="primary"
              defaultChecked={this.state.allChecked}
              onChange={(e) => {
                let temp = this.state.flight;
                temp.map((v) => {
                  v.checked = e.target.checked;
                  return v;
                });
                this.setState({
                  allChecked: e.target.checked,
                  flight: temp,
                });
              }}
            />
          ),
          sorting: false,
          field: "t_id",
          align: "center",
          render: (rowData) => {
            return (
              <Checkbox
                color="primary"
                checked={rowData.checked}
                onChange={(e) => {
                  let temp = this.state.flight;
                  temp.map((v) => {
                    if (v.t_id === rowData.t_id) {
                      v.checked = e.target.checked;
                    }
                    return v;
                  });
                  this.setState({
                    allChecked: false,
                    flight: temp,
                  });
                }}
              />
            );
          },
        },
      ];
    }
    this.columns = [
      ...this.columns,
      {
        title: "วัน และ เวลา",
        field: "TrnDate",
        // type: "datetime",
        align: "center",
        render: (rowData) => {
          return Helper.CastDate(new Date(rowData.TrnDate));
        },
      },
      { title: "สายการบิน", field: "AirportName", align: "center" },
      {
        title: "เลขที่สัญชาติ / เลขทะเบียน",
        field: "Callsign",
        align: "center",
      },
      { title: "รหัสเที่ยวบิน", field: "FlightNo", align: "center" },
      { title: "น้ำหนัก", field: "AirTypeGrossWeight", align: "center" },
    ];
  }
  handleFiltering = async (v) => {
    await this.setState({ q: Helper.jsonToQueryString(v) });
    await this.setState({ page: 0 });
    this.getTransport(0, this.state.rows, Helper.jsonToQueryString(v));
  };
  handleChangePage = async (e, page) => {
    await this.setState({ page: page });
    this.getTransport(page, this.state.rows, this.state.q);
  };

  handleChangeRowPerPage = async (row) => {
    await this.setState({ rows: row });
    await this.setState({ page: 0 });
    this.getTransport(0, row, this.state.q);
  };

  getTransport = (page = 0, rows = 10, q = "") => {
    try {
      this.props.handlerIsLoading(true);
      const startRow = page * rows;
      let airport = "";
      if ([3, 4].includes(Helper.GetUserData().ul_id)) {
        airport = `&filter_airport=${Helper.GetUserData().ap_id}`;
      }
      const queryString = `limit=${rows}&offset=${startRow}${q ? `&${q}` : ""}`;
      const apiUrl = `${process.env.REACT_APP_API_URL}/v1/transports/pagination-import?${queryString}${airport}`;
      axios.get(apiUrl).then(
        async (res) => {
          await res.data.data.map((v) => {
            v.checked = this.state.allChecked;
            return v;
          });
          await this.setState({ flight: res.data.data });
          await this.setState({ total: res.data.total });
          this.props.handlerIsLoading(false);
        },
        (err) => {
          Notifications.NotiError({ msg: "มีบางอย่างผิดพลาด" });
          this.props.handlerIsLoading(false);
        }
      );
    } catch (error) {
      Notifications.NotiError({ msg: "มีบางอย่างผิดพลาด" });
    }
  };
  confirmCheckedRows = async () => {
    const filterData = this.state.flight.filter((i) => i.checked);
    Notifications.NotiAsync(
      axios.post(`${process.env.REACT_APP_API_URL}/v1/transports/import`, {
        data: filterData,
      }),
      (resp) => {
        // this.setState({ flight: [], allChecked: false });
        this.props.handlerIsLoading(false);
        Notifications.NotiSuccess();
      },
      (err) => {
        this.props.handlerIsLoading(false);
        Notifications.NotiError({ msg: "มีบางอย่างผิดพลาด" });
      }
    );
  };
  getDataDisMount = () => {
    const user = JSON.parse(localStorage.getItem("DataUser"));
    if ([1, 2, 3].includes(user.ul_id)) {
      this.getTransport(0, this.state.rows, this.state.q);
    }
  };
  componentDidMount() {}
  render() {
    return (
      <Paper className="tabelData">
        <TableContainer>
          <MaterialTable
            title={
              <Button
                className={`styleBtn blue ${
                  this.user.ul_id === 4 ? "d-none" : ""
                }`}
                variant="contained"
                style={{ textTransform: "none", width: "auto" }}
                onClick={() => {
                  this.confirmCheckedRows();
                }}
                size="small"
              >
                ยืนยันที่เลือก
              </Button>
            }
            columns={this.columns}
            data={this.state.flight}
            options={{
              pageSize: 10,
              pageSizeOptions: [10, 25, 50, 100],
              search: true,
              exportButton: false,
              filtering: false,
              actionsColumnIndex: -1,
              grouping: true,
            }}
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  rowsPerPage={this.state.rows}
                  count={this.state.total ? parseInt(this.state.total) : 0}
                  page={this.state.page}
                  onChangePage={(e, page) => this.handleChangePage(e, page)}
                  onChangeRowsPerPage={(event) => {
                    props.onChangeRowsPerPage(event);
                    this.handleChangeRowPerPage(event.target.value);
                  }}
                />
              ),
            }}
          />
        </TableContainer>
      </Paper>
    );
  }
}

export default TableImportTransport;

/** @format */

import {
  Button,
  Paper,
  TableContainer,
  TablePagination,
  Tooltip,
} from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import EmailIcon from "@material-ui/icons/Email";
import GetApp from "@material-ui/icons/GetApp";
import Description from "@material-ui/icons/Publish";
import SendIcon from "@material-ui/icons/Send";
import axios from "axios";
import fileDownload from "js-file-download";
import MaterialTable from "material-table";
import React, { Component } from "react";
import Helper from "../global/Helper";
import Notifications from "../global/Notifications";
import SD from "../global/SwalDialog";
export class TableInvoiceSpecial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rows: 10,
      total: 0,
      flight: [],
      q: "",

      upload_id: "",
      upload_name: "",
    };
    this.upLoadfile = null;
  }
  handleFiltering = async (v) => {
    await this.setState({ q: Helper.jsonToQueryString(v) });
    await this.setState({ page: 0 });
    this.getData(0, this.state.rows, Helper.jsonToQueryString(v));
  };
  handleChangePage = async (e, page) => {
    await this.setState({ page: page });
    this.getData(page, this.state.rows, this.state.q);
  };

  handleChangeRowPerPage = async (row) => {
    await this.setState({ rows: row });
    await this.setState({ page: 0 });
    this.getData(0, row, this.state.q);
  };

  resend = (id) => {
    SD.Confirm({
      header: `ยืนยันการส่งใหม่`,
      msg: `ระบบจะเปลี่ยนแปลงข้อมูลวันที่เอกสารและวันที่เริ่มชำระเป็นวันปัจจุบัน`,
      type: "warning",
      fnConfirm: () => {
        this.props.handlerIsLoading(true);
        Notifications.NotiAsync(
          axios.post(`${process.env.REACT_APP_API_URL}/v1/invoice/resend`, {
            id: id,
            user: Helper.GetUserData().u_cgd_user,
            pass: Helper.GetUserData().u_cgd_pass,
            persontype: 2,
          }),
          async (resp) => {
            await this.getData();
            this.props.handlerIsLoading(false);
            Notifications.NotiSuccess();
          },
          (err) => {
            this.props.handlerIsLoading(false);
            Notifications.NotiError({ msg: "มีบางอย่างผิดพลาด" });
          }
        );
      },
    });
  };

  getData = (page = 0, rows = 10, q = "") => {
    this.props.handlerIsLoading(true);
    const startRow = page * rows;
    let queryString = `limit=${rows}&offset=${startRow}${q ? `&${q}` : ""}`;
    if (Helper.GetUserData().ul_id === 4) {
      queryString += `&filter_airline=${
        Helper.GetUserData().al_name
      }&filter_airport=${Helper.GetUserData().ap_id}`;
    }
    if (Helper.GetUserData().ul_id === 3) {
      queryString += `&filter_airport=${Helper.GetUserData().ap_id}`;
    }
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/invoice?${queryString}&is_special=1`;
    axios.get(apiUrl).then((res) => {
      const params = new URLSearchParams(q);
      const m = params.get("filter_type");
      res.data.data.forEach((v) => {
        v.td_arrival_freight_f = parseInt(v.td_arrival_freight_f) / 1000;
        v.status = Helper.getStatusInvoice(v.i_due_date, v.i_paid, v.i_active);

        if (["PSC", "Mulct-PSC"].includes(m)) {
          v.price = Helper.getCalculatePSC(
            parseInt(v.td_passenger_dom_f),
            parseInt(v.td_passenger_inter_f)
          );
        } else if (["APPS", "Mulct-APPS"].includes(m)) {
          v.price = Helper.getCalculateAPPS(parseInt(v.td_passenger_f));
        } else if (["Landing", "Mulct-Landing"].includes(m)) {
          v.price = Helper.getCalculateLanding(v.td_arrival_freight_f);
        } else if (["Parking", "Mulct-Parking"].includes(m)) {
          v.price = Helper.getCalculateParking(
            v.date_diff,
            v.td_arrival_freight_f
          );
        } else {
          v.price = 0;
        }
        v.discount = 0;
        v.percent = 0;
        v.changePrice = 0;
        v.total = v.price;
        v.remark = "";
      });
      this.setState({ flight: res.data.data, total: res.data.total });
      this.props.handlerIsLoading(false);
    });
  };

  componentDidMount() {
    this.getData();
  }

  getFilePDF = (name) => {
    this.props.handlerIsLoading(true);
    Notifications.NotiAsync(
      axios.get(
        `${process.env.REACT_APP_API_URL}/v1/download/invoices/pdf/${name}`,
        {
          responseType: "blob",
        }
      ),
      (resp) => {
        fileDownload(resp.data, name);
        this.props.handlerIsLoading(false);
        Notifications.NotiSuccess();
      },
      (err) => {
        this.props.handlerIsLoading(false);
        Notifications.NotiError({ msg: err.response.data.msg });
      }
    );
  };

  getFileDocx = (name) => {
    this.props.handlerIsLoading(true);
    Notifications.NotiAsync(
      axios.get(
        `${process.env.REACT_APP_API_URL}/v1/download/invoices/docx/${name}`,
        {
          responseType: "blob",
        }
      ),
      (resp) => {
        fileDownload(resp.data, `${name}.docx`);
        this.props.handlerIsLoading(false);
        Notifications.NotiSuccess();
      },
      (err) => {
        this.props.handlerIsLoading(false);
        Notifications.NotiError({ msg: err.response.data.msg });
      }
    );
  };

  getFileReceipt = (name) => {
    this.props.handlerIsLoading(true);
    Notifications.NotiAsync(
      axios.get(
        `${process.env.REACT_APP_API_URL}/v1/download/invoices/receipt/${name}`,
        {
          responseType: "blob",
        }
      ),
      (resp) => {
        fileDownload(resp.data, name);
        this.props.handlerIsLoading(false);
        Notifications.NotiSuccess();
      },
      (err) => {
        this.props.handlerIsLoading(false);
        Notifications.NotiError({ msg: "มีบางอย่างผิดพลาด" });
      }
    );
  };

  convertToCurrency = (number) => {
    if (Number(number)) {
      return new Intl.NumberFormat("en").format(number);
    }
    return "0.00";
  };
  cancelInvoice = (data) => {
    SD.Confirm({
      header: `ยืนยันการยกเลิกใบแจ้งหนี้ "${data.i_doc_no}"`,
      msg: `การยกเลิกจะเป็นการคืนสถานะเที่ยวบินทั้งหมดไปสู่สถานะยืนยันข้อมูล`,
      type: "warning",
      fnConfirm: () => {
        Notifications.NotiAsync(
          axios.delete(`${process.env.REACT_APP_API_URL}/v1/invoice/delete`, {
            data: {
              ...data,
            },
          }),
          (resp) => {
            this.getData();
            Notifications.NotiSuccess();
          },
          (err) => {
            Notifications.NotiError({ msg: "ไม่สามารถลบข้อมูลได้" });
          }
        );
      },
    });
  };

  sendBillUpload = (e) => {
    this.props.handlerIsLoading(true);
    var formData = new FormData();
    var file = document.querySelector("#billUpload");
    formData.append("file", file.files[0]);
    formData.append("id", this.state.upload_bill_id);
    formData.append("name", this.state.upload_bill_name);
    formData.append("uid", Helper.GetUserData().u_id);
    Notifications.NotiAsync(
      axios.post(
        `${process.env.REACT_APP_API_URL}/v1/invoice/uploadbill`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ),
      (resp) => {
        document.querySelector("#billUpload").value = "";
        this.props.handlerIsLoading(false);
        Notifications.NotiSuccess();
        this.getData();
      },
      (err) => {
        document.querySelector("#billUpload").value = "";
        this.props.handlerIsLoading(false);
        Notifications.NotiError({ msg: "มีบางอย่างผิดพลาด" });
      }
    );
  };

  sendFileUpload = (e) => {
    this.props.handlerIsLoading(true);
    var formData = new FormData();
    var file = document.querySelector("#filUpload");
    formData.append("file", file.files[0]);
    formData.append("id", this.state.upload_id);
    formData.append("name", this.state.upload_name);
    formData.append("uid", Helper.GetUserData().u_id);
    Notifications.NotiAsync(
      axios.post(
        `${process.env.REACT_APP_API_URL}/v1/invoice/uploadfile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ),
      (resp) => {
        document.querySelector("#filUpload").value = "";
        this.props.handlerIsLoading(false);
        Notifications.NotiSuccess();
        this.getData();
      },
      (err) => {
        document.querySelector("#filUpload").value = "";
        this.props.handlerIsLoading(false);
        Notifications.NotiError({ msg: err.response.data.msg });
      }
    );
  };

  getFileBill = (name) => {
    this.props.handlerIsLoading(true);
    Notifications.NotiAsync(
      axios.get(
        `${process.env.REACT_APP_API_URL}/v1/download/invoices/bill/${name}`,
        {
          responseType: "blob",
        }
      ),
      (resp) => {
        fileDownload(resp.data, name);
        this.props.handlerIsLoading(false);
        Notifications.NotiSuccess();
      },
      (err) => {
        this.props.handlerIsLoading(false);
        Notifications.NotiError({ msg: "มีบางอย่างผิดพลาด" });
      }
    );
  };

  sendMail = (id) => {
    this.props.handlerIsLoading(true);
    var formData = new FormData();
    formData.append("id", id);
    formData.append("uid", Helper.GetUserData().u_id);
    Notifications.NotiAsync(
      axios.post(
        `${process.env.REACT_APP_API_URL}/v1/invoice/sendmail`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ),
      (resp) => {
        this.props.handlerIsLoading(false);
        Notifications.NotiSuccess();
      },
      (err) => {
        this.props.handlerIsLoading(false);
        Notifications.NotiError({ msg: "มีบางอย่างผิดพลาด" });
      }
    );
  };
  isOverDue = (rowData) => {
    return (
      Helper.getStatusInvoice(
        rowData.i_due_date,
        rowData.i_paid,
        rowData.i_active,
        false
      ) === "ค้างชำระ"
    );
  };

  sendReceiptUpload = (e) => {
    this.props.handlerIsLoading(true);
    var formData = new FormData();
    var file = document.querySelector("#receiptUpload");
    formData.append("file", file.files[0]);
    formData.append("id", this.state.upload_bill_id);
    formData.append("uid", Helper.GetUserData().u_id);
    Notifications.NotiAsync(
      axios.post(
        `${process.env.REACT_APP_API_URL}/v1/invoice/uploadreceipt`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ),
      (resp) => {
        document.querySelector("#receiptUpload").value = "";
        this.props.handlerIsLoading(false);
        Notifications.NotiSuccess();
        this.getData();
      },
      (err) => {
        document.querySelector("#receiptUpload").value = "";
        this.props.handlerIsLoading(false);
        Notifications.NotiError({ msg: "มีบางอย่างผิดพลาด" });
      }
    );
  };

  render() {
    return (
      <div>
        <input
          id="billUpload"
          type="file"
          className="hidden"
          accept="application/pdf"
          ref={(input) => {
            this.upLoadbill = input;
          }}
          onChange={(e) => {
            e.preventDefault();
            this.sendBillUpload(e);
          }}
        />
        <input
          id="filUpload"
          type="file"
          className="hidden"
          accept="application/pdf"
          ref={(input) => {
            this.upLoadfile = input;
          }}
          onChange={(e) => {
            e.preventDefault();
            this.sendFileUpload(e);
          }}
        />
        <input
          id="receiptUpload"
          type="file"
          className="hidden"
          accept="application/pdf"
          ref={(input) => {
            this.upLoadReceipt = input;
          }}
          onChange={(e) => {
            e.preventDefault();
            this.sendReceiptUpload(e);
          }}
        />
        <Paper className="tabelData">
          <TableContainer>
            <MaterialTable
              title=""
              columns={[
                {
                  title: "วันที่ออก",
                  field: "i_doc_date",
                  // type: "datetime",
                  align: "center",
                  render: (rowData) => {
                    return Helper.CastDate(new Date(rowData.i_doc_date));
                  },
                },
                {
                  title: "เลขที่ใบแจ้งหนี้",
                  field: "i_doc_no",
                  align: "center",
                },
                {
                  title: "ท่าอากาศยาน",
                  field: "ap_name",
                  align: "center",
                  hidden: ![2, 1].includes(Helper.GetUserData().ul_id),
                },
                { title: "สายการบิน", field: "al_name", align: "center" },
                { title: "ประเภท", field: "i_revenue", align: "center" },
                { title: "Ref 1", field: "i_ref1", align: "center" },
                { title: "Ref 2", field: "i_ref2", align: "center" },
                {
                  title: "จำนวนเงิน",
                  field: "i_total",
                  align: "center",
                  render: (rowData) => {
                    return this.convertToCurrency(rowData.i_total);
                  },
                },
                { title: "CGD Status", field: "cgdstatus", align: "center" },
                { title: "สถานะ", field: "status", align: "center" },
                {
                  title: "",
                  field: "t_id",
                  align: "center",
                  render: (rowData) => {
                    return (
                      <div style={{ display: "flex" }}>
                        <Tooltip title="ดู" arrow>
                          <Button
                            className="w-auto styleBtn blue"
                            href={`/invoice-special/detail/${encodeURI(
                              rowData.i_id
                            )}`}
                            variant="contained"
                            style={{
                              textTransform: "none",
                              marginRight: "5px",
                            }}
                            size="small"
                          >
                            <AssignmentIcon />
                          </Button>
                        </Tooltip>

                        <Tooltip title="Resend" arrow>
                          <span>
                            <Button
                              className={`w-auto styleBtn ${
                                rowData.i_cgd === "1" ||
                                rowData.i_active === "1" ||
                                this.isOverDue(rowData)
                                  ? ""
                                  : "orangedark"
                              }`}
                              variant="contained"
                              disabled={
                                rowData.i_cgd === "1" ||
                                rowData.i_active === "1" ||
                                this.isOverDue(rowData)
                              }
                              style={{
                                textTransform: "none",
                                marginRight: "5px",
                              }}
                              size="small"
                              onClick={() => {
                                this.resend(rowData.i_id);
                              }}
                            >
                              <SendIcon />
                            </Button>
                          </span>
                        </Tooltip>

                        <Tooltip title="ยกเลิก" arrow>
                          <span>
                            <Button
                              className={`w-auto styleBtn ${
                                rowData.i_paid === "1" ||
                                rowData.i_active === "1"
                                  ? ""
                                  : "red"
                              }`}
                              variant="contained"
                              style={{
                                textTransform: "none",
                                marginRight: "5px",
                              }}
                              disabled={
                                rowData.i_paid === "1" ||
                                rowData.i_active === "1"
                              }
                              size="small"
                              onClick={() => {
                                this.cancelInvoice(rowData);
                              }}
                            >
                              <CancelPresentationIcon />
                            </Button>
                          </span>
                        </Tooltip>

                        {/* <Tooltip title="สร้างใหม่" arrow>
                          <span>
                            <Button
                              className={`w-auto styleBtn ${
                                Helper.getStatusInvoice(
                                  rowData.i_due_date,
                                  rowData.i_paid,
                                  rowData.i_active,
                                  false
                                ) === "ค้างชำระ" && rowData.i_active === "0"
                                  ? "green"
                                  : ""
                              }`}
                              variant="contained"
                              style={{
                                textTransform: "none",
                                marginRight: "5px",
                              }}
                              disabled={
                                Helper.getStatusInvoice(
                                  rowData.i_due_date,
                                  rowData.i_paid,
                                  rowData.i_active,
                                  false
                                ) !== "ค้างชำระ" || rowData.i_active === "1"
                              }
                              size="small"
                              href={`#`}
                            >
                              <AutorenewIcon />
                            </Button>
                          </span>
                        </Tooltip> */}

                        <Tooltip title="Upload Bill Payment" arrow>
                          <span>
                            <Button
                              className={`w-auto styleBtn ${
                                rowData.i_active === "1" ? "" : "yellow"
                              }`}
                              variant="contained"
                              style={{
                                textTransform: "none",
                                marginRight: "5px",
                              }}
                              disabled={rowData.i_active === "1"}
                              size="small"
                              onClick={() => {
                                SD.Input({
                                  msg: "เลขที่เอกสาร",
                                  value: rowData.upload_bill_name,
                                  btn: "Upload",
                                  fnConfirm: (value) => {
                                    if (value.status) {
                                      if (value.value) {
                                        this.upLoadbill.click();
                                      } else {
                                        Notifications.NotiWarning({
                                          msg: "กรุณากรอกเลขที่เอกสาร",
                                        });
                                      }
                                    }
                                    rowData.upload_bill_name = value.value;
                                    this.setState({
                                      upload_bill_id: rowData.i_id,
                                      upload_bill_name: value.value,
                                    });
                                  },
                                });
                              }}
                            >
                              <Description />
                            </Button>
                          </span>
                        </Tooltip>

                        <Tooltip title="Download Bill Payment" arrow>
                          <span>
                            <Button
                              className={`w-auto styleBtn ${
                                rowData.i_active === "1" ||
                                !rowData.i_upload_bill
                                  ? ""
                                  : "lightpurple"
                              }`}
                              variant="contained"
                              style={{
                                textTransform: "none",
                                marginRight: "5px",
                              }}
                              disabled={
                                rowData.i_active === "1" ||
                                !rowData.i_upload_bill
                              }
                              size="small"
                              onClick={() => {
                                this.getFileBill(rowData.i_upload_bill);
                              }}
                            >
                              <GetApp />
                            </Button>
                          </span>
                        </Tooltip>

                        <Tooltip title="อัพโหลดใบเสร็จรับเงิน" arrow>
                          <span>
                            <Button
                              className={`w-auto styleBtn receipt ${
                                ""
                                /*
                                  rowData.i_paid === "1" ||
                                  rowData.i_active === "1" ||
                                  Helper.isOverDue(rowData)
                                  ? ""
                                  : "receipt"
                                */
                              }`}
                              variant="contained"
                              style={{
                                textTransform: "none",
                                marginRight: "5px",
                              }}
                              disabled={
                                false
                                // rowData.i_paid === "1" ||
                                // rowData.i_active === "1" ||
                                // Helper.isOverDue(rowData)
                              }
                              size="small"
                              onClick={() => {
                                this.setState({
                                  upload_bill_id: rowData.i_id,
                                });
                                this.upLoadReceipt.click();
                              }}
                            >
                              <Description />
                            </Button>
                          </span>
                        </Tooltip>
                        <Tooltip title="ดาวน์โหลดใบเสร็จรับเงิน" arrow>
                          <span>
                            <Button
                              className={`w-auto styleBtn ${
                                //(
                                // rowData.i_active === "1" ||
                                !rowData.i_upload_receipt
                                  ? // || Helper.isOverDue(rowData)) &&
                                    // rowData.i_paid !== "1"
                                    ""
                                  : "receipt-download"
                              }`}
                              variant="contained"
                              style={{
                                textTransform: "none",
                                marginRight: "5px",
                              }}
                              disabled={
                                //(rowData.i_active === "1" ||
                                !rowData.i_upload_receipt
                                //|| Helper.isOverDue(rowData)) &&
                                //rowData.i_paid !== "1"
                              }
                              size="small"
                              onClick={() => {
                                this.getFileReceipt(rowData.i_upload_receipt);
                              }}
                            >
                              <GetApp />
                            </Button>
                          </span>
                        </Tooltip>

                        <Tooltip title="Email" arrow>
                          <span>
                            <Button
                              className={`w-auto styleBtn ${
                                !rowData.i_upload && !rowData.i_upload_bill
                                  ? ""
                                  : "yellowdark"
                              }`}
                              variant="contained"
                              disabled={
                                !rowData.i_upload && !rowData.i_upload_bill
                              }
                              style={{
                                textTransform: "none",
                                marginRight: "5px",
                              }}
                              size="small"
                              onClick={() => {
                                this.sendMail(rowData.i_id);
                              }}
                            >
                              <EmailIcon />
                            </Button>
                          </span>
                        </Tooltip>
                      </div>
                    );
                  },
                },
              ]}
              data={this.state.flight}
              options={{
                pageSize: 10,
                pageSizeOptions: [10, 25, 50, 100],
                search: true,
                exportButton: true,
                filtering: false,
                actionsColumnIndex: -1,
                grouping: true,
                exportPdf: (columns, data) => {
                  const temp = data.map((i) => [
                    i.i_doc_date,
                    i.i_doc_no,
                    i.al_name,
                    i.i_revenue,
                    i.icr_refno1,
                    i.icr_refno2,
                    i.i_total,
                    this.getStatus(i.i_due_date, i.i_paid, false),
                  ]);

                  Helper.exportPDF(
                    [
                      "วันที่ออก",
                      "เลขที่ใบแจ้งหนี้",
                      "สายการบิน",
                      "ประเภท",
                      "Ref 1",
                      "Ref 2",
                      "จำนวนเงิน",
                      "สถานะ",
                    ],
                    temp
                  );
                },
              }}
              components={{
                Pagination: (props) => (
                  <TablePagination
                    {...props}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    rowsPerPage={this.state.rows}
                    count={this.state.total ? parseInt(this.state.total) : 0}
                    page={this.state.page}
                    onChangePage={(e, page) => this.handleChangePage(e, page)}
                    onChangeRowsPerPage={(event) => {
                      props.onChangeRowsPerPage(event);
                      this.handleChangeRowPerPage(event.target.value);
                    }}
                  />
                ),
              }}
            />
          </TableContainer>
        </Paper>
      </div>
    );
  }
}

export default TableInvoiceSpecial;
